import { useLocation } from "react-router-dom"
  
  function  LinkTo (link) {       
    const location = useLocation();
    const isCurrentlyEn = location.pathname.startsWith('/en');
    const isCurrentlyRu = location.pathname.startsWith('/ru');
    const language = isCurrentlyEn ? 'en' : isCurrentlyRu ? 'ru' : ''
    return(language + link)
}

export default LinkTo