import Logo from '../imgs/icon_white.png'

function  GlobalData (i) {    
    const texts = {
        'apiUrl2' : 'https://zz.zzzam.ru/api/v1/',  
        'imgUrl2' : 'https://zz.zzzam.ru/public/storage/images/',
        'loading' : <div className="flex justify-center my-20 text-gray-400">
        <div className="bg-gray-100 rounded-3xl animate-pulse">
            <img src={Logo} alt="Loading" className='h-auto w-14 mx-3 my-3.5 filter grayscale contrast-100 brightness-50'/>
        </div>
      </div>,
    }   
    return(texts[i])
}

export default GlobalData