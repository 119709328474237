import GlobalText from "./globalText";
import { PencilIcon, TrashIcon, XMarkIcon, CheckIcon, PlusIcon, MinusIcon } from '@heroicons/react/24/outline'
import React, { useState, useEffect, useRef } from 'react'
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Pagination, EffectCreative, Autoplay, Virtual, Navigation, EffectFlip  } from 'swiper/modules';
import GlobalData from "./globalData";
import { PhotoIcon } from '@heroicons/react/24/outline'
import { FileInput, Label } from "flowbite-react";
import LogoW from '../imgs/icon_white.png'
import Logo from '../imgs/favicon.png'
import { Link, useLocation } from 'react-router-dom';
import { FaRegCalendar} from "react-icons/fa6";
import { FaRegFileAlt } from "react-icons/fa";
import { MdAccessTime } from "react-icons/md";
import { Toaster, toast } from 'sonner'
import moment from 'moment';
import 'moment/locale/ru'; 
import 'moment/locale/en-gb'; 
import axios from 'axios';
import MapComponent from "./MapComponent";


function formattedDate (dateString, lang) {

  const monthNamesTg = [
    'январи', 'феврали', 'марти', 'апрели', 'майи', 'июни',
    'июли', 'августи', 'сентябри', 'октябри', 'ноябри', 'декабри'
  ];
  
  const currentMonth = moment(dateString).month();
  const currentMonthTg = monthNamesTg[currentMonth];

  if(lang === 'en'){
    const _formattedDate = moment(dateString).locale('en-gb').format('MMMM DD YYYY  HH:mm');
    return _formattedDate;
  }else if(lang === 'ru'){
    const _formattedDate = moment(dateString).locale('ru').format('DD MMMM YYYY  HH:mm');
    return _formattedDate;
  }else{
    const _formattedDate = moment(dateString).format('DD ') + currentMonthTg + moment(dateString).format(' YYYY  HH:mm');
    return _formattedDate;
  }
  
};

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

  function ReturnPage(props) {    
    const {component, news, language, newspage, calculators, cals_time} = props; 
    const APIURL = process.env.REACT_APP_API_URL
    const IMGURL = process.env.REACT_APP_IMG_URL
    const FILESURL = process.env.REACT_APP_FILES_URL
    const [showCards, setshowCards] = useState((component.data.showAll && component.data.showAll.toString() === '1') ? 'all' : '0');  
    useEffect(() => {
      setshowCards((component.data.showAll && component.data.showAll.toString() === '1') ? 'all' : '0')
  }, [component.data.showAll]);
    const swiperRef = useRef(null);
    const [isHovered, setIsHovered] = useState(null);
    const [thisrategroup, setthisrategroup] = useState(0);
    const [qurbaz, setqurbaz] = useState(0)
    const [qurbazshow, setqurbazshow] = useState(false)
    const [qurbba, setqurbba] = useState(3)
    const [qurbbashow, setqurbbashow] = useState(false)
    const [qBuyOrSell, setqBuyOrSell] = useState(false)
    const [calculatorsActive, setcalculatorsActive] = useState(0)
    const [columnActive, setColumnActive] = useState(0)
    const convertToRef = useRef(null);
    const [debtsum, setdebtsum] = useState(1000)
    const [depsum, setdepsum] = useState(10000)
    const [debtpercent, setdebtpercent] = useState(20)
    const [deppercent, setdeppercent] = useState(13)
    const [debtterm, setdebtterm] = useState(12)
    const [depterm, setdepterm] = useState(12)
    const [overpayment, setoverpayment] = useState(0)
    const [income, setincome] = useState(0)
    const [deptax, setdeptax] = useState(true)
    const [monthlyincome, setmonthlyincome] = useState(0)
    const [monthly, setmonthly] = useState(0)
    const { hash } = useLocation();
    const blockRef = useRef(null);
    const location = useLocation();   
    const [loading, setLoading] = useState(false);
    const [atmsactive, setatmsactive] = useState(0);
    const [centerMap, setCenterMap] = useState({});
    const [markers, setMarkers] = useState([]);
    const [slideIndex, setSlideIndex] = useState(0);
  
  
    useEffect(() => {
      if (location.hash === '#getBlock' && blockRef.current) {
        blockRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
      }
    }, [location]);
  
    useEffect(() => {
      if (hash) {
        setTimeout(() => {
          const element = document.getElementById(hash.replace('#', ''));
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 0); 
      }
    }, [hash]);


    const currencies = [
      { name: 'USD' },
      { name: 'RUB' },
      { name: 'EUR' },
      { name: 'TJS' }
    ]
    const succesText = GlobalText(151)

    const [openSection, setOpenSection] = useState(null);
    const [adresssectionactive, setadresssectionactive] = useState(0)

    const toggleAccordion = (index) => {
      setOpenSection(openSection === index ? null : index);
    };

    const handleSlideChange = (swiper) => {
      const currentIndex = swiperRef.current.swiper.activeIndex;
      setSlideIndex(currentIndex);
    };
    

    useEffect(() => {
      depcal();
    }, [depsum, deppercent, depterm, deptax]);

    function depcal () {
      const perc = depsum * (deppercent * (depterm / 12)) / 100
      const tax = perc * 12 / 100
      const mont = perc / depterm
      const monttax = mont * 12 / 100
      if(deptax){
        setincome(perc - tax)
        setmonthlyincome(mont - monttax)
      }else{
        setincome(perc)
        setmonthlyincome(mont)
      }      
    }

    useEffect(() => {
      debtcal();
    }, [debtsum, debtpercent, debtterm]);

    function debtcal () {
      const perc = debtsum * (debtpercent * (debtterm / 12)) / 100
      const mont = perc / debtterm
      setoverpayment(perc)
      setmonthly(mont)
    }
    const [value, setValue] = useState('');
    const handleInput = (e) => {
      const inputValue = e.target.value;
      // Удаление всех символов, кроме букв и пробелов
      const sanitizedValue = inputValue.replace(/[^a-zA-Zа-яА-ЯёЁ\s]/g, '');
      setValue(sanitizedValue);
    };

    function sanitizeInput(inputValue) {
      const startsWithPlus = inputValue.startsWith('+');
      let sanitizedValue = inputValue.replace(/[^0-9]/g, '');
      if (startsWithPlus) {
        sanitizedValue = '+' + sanitizedValue;
      }
    
      return sanitizedValue;
    }

    const [value2, setValue2] = useState('');
    const handleInput2 = (e) => {
      const inputValue2 = e.target.value;
      const sanitizedValue2 = sanitizeInput(inputValue2)
      setValue2(sanitizedValue2);
    };

    useEffect(() => {
        convertto();
    }, [qurbaz, qurbba, thisrategroup, calculators, qBuyOrSell, calculatorsActive]);

    function convertto(){      
      
      if(convertToRef.current){ 
        const az = convertToRef.current.value
        if(qurbaz === 3){
          if(qurbba === 3){
            document.getElementById('convertfrom').value = az
          }else{
            const ba = !qBuyOrSell ? calculators[thisrategroup]['data'][qurbba].value_buy : calculators[thisrategroup]['data'][qurbba].value_sale
            document.getElementById('convertfrom').value = (az / ba).toFixed(2)
          }        
        }else{
          if(qurbba === 3){
            const ba = calculators[thisrategroup] ? !qBuyOrSell ? calculators[thisrategroup]['data'][qurbaz].value_sale : calculators[thisrategroup]['data'][qurbaz].value_buy : null
            document.getElementById('convertfrom').value = (az * ba).toFixed(2)
          }else{
            const az_s = az * ( !qBuyOrSell ? calculators[thisrategroup]['data'][qurbaz].value_buy : calculators[thisrategroup]['data'][qurbaz].value_sale)
            const ba = !qBuyOrSell ? calculators[thisrategroup]['data'][qurbba].value_buy : calculators[thisrategroup]['data'][qurbba].value_sale
            document.getElementById('convertfrom').value = (az_s / ba).toFixed(2)
          }
        }
      }
    }
    
    const getImageUrl = (img_pre) => {
      if (img_pre && (img_pre instanceof Blob || img_pre instanceof File)) {
        try {
          return URL.createObjectURL(img_pre);
        } catch (error) {
          return null;
        }
      } else {
        return null;
      }
    }; 
    const formatDate = (dateString) => {
      const date = new Date(dateString);
      const day = String(date.getDate()).padStart(2, '0');
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const year = date.getFullYear();
      return `${day}.${month}.${year}`;
    };

    const [flippedCards, setFlippedCards] = useState({});
    const [isHoveredCards, setHoveredCards] = useState({});

    const handleCardClick = (id) => {
      setFlippedCards((prev) => ({ ...prev, [id]: !prev[id] }));
    };

    const handleMouseEnter = (id) => {
      setHoveredCards((prev) => ({ ...prev, [id]: true }));
    };

    const handleMouseLeave = (id) => {
      setHoveredCards((prev) => ({ ...prev, [id]: false }));
    };

    useEffect(() => {
      if (
        component.data?.items &&
        Array.isArray(component.data.items) &&
        component.data.items.length > 1 && 
        component.data.items[1]?.items &&
        component.data.items[1].items[atmsactive]
      ) {
        const item = component.data.items[1].items[atmsactive];
    
        if (item.lat && item.lng) {
          setCenterMap({
            lat: Number(item.lat),
            lng: Number(item.lng),
          });
    
          const newMarkers = item.items?.map(subItem => ({
            position: { lat: Number(subItem.lat), lng: Number(subItem.lng) },
          })) || [];
    
          setMarkers(newMarkers);
        }
      }
    }, [atmsactive, columnActive]);


    const showMap = () => {
      if(markers.length > 0){     
        return <MapComponent center={centerMap} markers={markers} key={JSON.stringify(markers)}/>;
      }else{
        return <div></div>;
      }
    }
    
    if(component.name === 'text'){
      return(<div className='px-6 xl:px-0 mx-auto max-w-6xl mb-5 text-xl text-gray-900 sm:text-2xl sm:tracking-tight  items-center py-10'>
        <p style={{ 
              color: component.data.title_color,
              fontWeight: component.data.title_bold,
              fontStyle: component.data.title_style,
            }}>{component.data.title}</p>
      </div>)
    }
    if(component.name === 'slider'){
      return(<div className=" rounded-3xl py-6 px-6 xl:px-0">
        <Swiper 
          autoHeight={true}
          slidesPerView={1}
          spaceBetween={30}
          loop={true}
          speed={500}
          autoplay={{
            delay: 4000,
            disableOnInteraction: true,          
          }}
          pagination={{
            clickable: true
          }}
          modules={[Autoplay, EffectCreative, Pagination]}
        className="swiper  max-w-6xl rounded-3xl "
          creativeEffect={{
          prev: {
            shadow: false,
            translate: ['-120%', 0, -500],
            
          },
          next: {
            shadow: false,
            translate: ['120%', 0, -500],
          },
        }}
      >
        {component.data.slides && component.data.slides.map((slide, index) => (
          <SwiperSlide className="">
            <div className="bg-white rounded-3xl " >
            <div className="mx-0 ">
              <div className="relative isolate overflow-hidden bg-gray-900 px-6 pt-10 rounded-3xl sm:px-10 md:pt-15 lg:flex lg:gap-x-20 lg:px-20 lg:pt-0 h-96" 
                style={{ 
                  backgroundColor: slide.bgColor,
                  height: 450
                }}>
                
                <div className="mx-auto text-left lg:mx-0 lg:flex lg:text-left lg:items-center">
                  <div>
                  <h2 className="text-3xl tracking-tight text-white sm:text-4xl" 
                  style={{ 
                    color: slide.titleColor,
                    fontWeight: slide.titleBold,
                    fontStyle: slide.titleStyle,
                  }}>
                    {slide.title}
                  </h2>
                  <p className="mt-6 text-lg leading-6 text-gray-300" style={{ 
                    color: slide.subtitleColor,
                    fontWeight: slide.subtitleBold,
                    fontStyle: slide.subtitleStyle,
                  }}>
                    {slide.subtitle}
                  </p>
                  <div className='flex'>
                  <Link to={slide.buttonUrl}
                      className="z-40 absolute bottom-10 inset-x-5 text-center rounded-lg brt-bg-blue px-3.5 py-3 font-semibold hover:bg-blend-darken lg:static lg:mt-10 lg:flex lg:items-center lg:justify-center lg:gap-x-6 lg:justify-start lg:inset-0"
                    style={{ 
                      backgroundColor: slide.buttonBg,
                    }}>
                      <span style={{ 
                      color: slide.buttonTitleColor,
                      fontWeight: slide.buttonTitleBold,
                      fontStyle: slide.buttonTitleStyle,
                      textTransform: slide.buttonTitleTransform
                    }}>{slide.buttonTitle}</span>
                    </Link>
                    </div>
                  </div>
                  
                </div>
                <div className="relative mt-0 lg:mb-0 flex items-center justify-center px-6">
                {slide.img ? <div className="flex justify-center items-center ">
                        <img
                          alt=""
                          className='max-w-full lg:max-w-md'
                          style={{width:'400px'}}
                          src={IMGURL + slide.img}
                        />
                      </div> : <div className="flex justify-center items-center">
                    </div>}
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        ) )}
      </Swiper>
      </div>)
    }
    if(component.name === '2block'){
      return(<div className="py-6 grid grid-flow-row md:grid-flow-col gap-4 mx-auto max-w-6xl px-6 xl:px-0" >
        {component.data.blocks && component.data.blocks.map((block, index) => (
            <article className="relative flex w-full flex-wrap justify-center rounded-3xl md:flex-nowrap p-4 py-8"
              style={{ 
                backgroundColor: block.bgColor,
              }}>
            <div className="flex w-full flex-col justify-center px-4 pt-5 md:pr-0 md:py-7 md:pl-7 ">
              <header className="mb-4.2 max-w-[360px] md:mb-7.25 pl-2 pt-2 sm:pl-0 sm:pt-0">
                <h2 className="text-xl tracking-tight sm:text-xl text-bold " style={{ 
                      color: block.titleColor,
                      fontWeight: block.titleBold,
                      fontStyle: block.titleStyle,
                    }}>{block.title}</h2>
                <div className="rich-text-editor bodyNormal mt-1.5 md:mt-3">
                  <p style={{ 
                      color: block.subtitleColor,
                      fontWeight: block.subtitleBold,
                      fontStyle: block.subtitleStyle,
                    }}>
                    {block.subtitle}
                  </p>
                </div>
              </header>
              <Link className="inline-block hidden md:block mt-6 " 
                to={block.buttonUrl}>
                <button
                  type="button"
                  className="group grid grid-flow-col items-center justify-center outline-none duration-150 text-base leading-5 gap-1 text-green hover:text-green-90 active:text-green-100 disabled:text-grey rounded-lg"
                  aria-label={block.buttonTitle}
                >
                  <span className='' style={{ 
                    color: block.buttonTitleColor,
                    fontWeight: block.buttonTitleBold,
                    fontStyle: block.buttonTitleStyle,
                    textTransform: block.buttonTitleTransform
                  }}>{block.buttonTitle}</span>
                  <div className="fill-blue group-hover:fill-green-90 group-active:fill-green-100 group-disabled:fill-grey "
                    style={{ 
                      color: block.buttonTitleColor,
                      fontWeight: block.buttonTitleBold,
                      fontStyle: block.buttonTitleStyle,
                      textTransform: block.buttonTitleTransform
                    }}>
                    <svg
                      width={20}
                      height={20}
                      viewBox="0 0 20 20"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.4226 3.57741L10.8333 2.98816L9.65482 4.16667L10.2441 4.75593L14.6548 9.16667L3.33333 9.16667H2.5V10.8333L3.33333 10.8333L14.6548 10.8333L10.2441 15.2441L9.65482 15.8333L10.8333 17.0118L11.4226 16.4226L17.2559 10.5893C17.5814 10.2638 17.5814 9.73618 17.2559 9.41075L11.4226 3.57741Z"
                      />
                    </svg>
                  </div>
                </button>
              </Link>
            </div>
            <figure className="flex w-full items-center justify-center md:justify-end">
              <div className="absolute bottom-5 left-5 z-10 md:hidden">
                <Link to={block.buttonUrl}>
                  <div className="flex whitespace-nowrap" style={{ 
                        color: block.buttonTitleColor
                      }}>
                      {block.buttonTitle} 
                    <span className="pl-1" style={{ 
                        color: block.buttonTitleColor
                      }}>
                      <svg
                        width={24}
                        height={24}
                        viewBox="0 0 24 24"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M13.7686 5.21126L13.0615 4.50415L11.6473 5.91836L12.3544 6.62547L17.6473 11.9184L4.06152 11.9184H3.06152V13.9184H4.06152L17.6473 13.9184L12.3544 19.2113L11.6473 19.9184L13.0615 21.3326L13.7686 20.6255L20.7686 13.6255C21.1592 13.2349 21.1592 12.6018 20.7686 12.2113L13.7686 5.21126Z"
                        />
                      </svg>
                    </span>
                  </div>
                </Link>
              </div>
              <div className="relative mt-0 mb-20 lg:mb-0 flex items-center justify-center">
                  {getImageUrl(block.img_pre) ? <div className="flex justify-center items-center ">
                      <img
                        alt=""
                        className='max-w-full lg:max-w-md'
                        style={{width:'250px'}}
                        src={getImageUrl(block.img_pre)}
                      />
                    </div> : block.img ? <div className="flex justify-center items-center ">
                          <img
                            alt=""
                            className='max-w-full lg:max-w-md'
                            style={{width:'250px'}}
                            src={IMGURL + block.img}
                          />
                        </div> : <div className="flex justify-center items-center my-3 mx-3">
                          <PhotoIcon className='text-gray-300 h-52 items-center '/>
                      </div>}
                  </div>
            </figure>
          </article>
          ) )}
        </div>)
    }
    if(component.name === 'cards'){      
      
      return(<div className="mx-auto max-w-6xl px-6 xl:px-0 py-6">        
        <h1 class="text-3xl"
            style={{
              color: component.data.titleColor,
              fontWeight: component.data.titleBold,
              fontStyle: component.data.titleStyle,
            }}
          >{component.data.title}</h1>
        <div class="hide-scrollbar  flex space-x-1 overflow-auto my-4">
            {component.data.showAll === '1' && <button type="button" aria-label={GlobalText(103)}
              className={classNames(
                showCards === 'all' ? 'text-white' : 'bg-blue-50 text-gray-400',
                ' whitespace-nowrap rounded-full py-2.5 px-4 '
              )}
              style={showCards === 'all' ? {backgroundColor: '#2267B4' } : {}}
              onClick={() => setshowCards('all')}>
              <p>{GlobalText(103)}</p>
            </button>}
            {component.data.categories && component.data.categories.map((element2, cindex) => (
              <button type="button" aria-label={element2.name} 
                className={classNames(
                 showCards === cindex.toString() ? 'text-white' : 'bg-blue-50 text-gray-400',
                  ' whitespace-nowrap rounded-full py-2.5 px-4 '
                )} 
                style={showCards === cindex.toString() ? {backgroundColor: '#2267B4' } : {}}
                onClick={() => setshowCards(cindex.toString())}>
                <p>{element2.name}</p>
              </button>
            ))}
            
        </div>
        {component.data.cards && component.data.cards.map((element, index) => (
          (showCards === 'all' || showCards.toString() === element.cardType.toString()) && <div className="py-2">            
            <article className=" mb-4 flex flex-wrap items-center rounded-3xl px-5 py-8 md:px-0 md:py-5" style={{
              background: element.bgColor,
            }}>
              <div className="mt-4.2 md:mt-0">
                <div className="md:hidden">
                  <div>
                    <h2 className="font-bold text-2xl"
                        style={{
                          color: element.titleColor,
                          fontWeight: element.titleBold,
                          fontStyle: element.titleStyle
                        }}
                      >
                        {element.title}
                    </h2>
                    <p className="mt-1 text-base leading-5" style={{
                        color: element.subtitleColor,
                        fontWeight: element.subtitleBold,
                        fontStyle: element.subtitleStyle
                      }}>
                      {element.subtitle}
                    </p>
                  </div>
                </div>
                <div className=''>
                  {element.img ? <div className=" px-5 flex justify-center items-center">      
                    <div
                      className={`card my-5 ${flippedCards[index] ? 'flipped' : ''} ${isHoveredCards[index] ? 'hovered' : ''}`}
                      onClick={() => handleCardClick(index)}
                      onMouseEnter={() => handleMouseEnter(index)}
                      onMouseLeave={() => handleMouseLeave(index)}
                    >
                        <div className="front flex justify-center items-center"> 
                          <img src={IMGURL + element.img} style={{maxHeight: '340px', maxWidth: '300px'}}/>                    
                        </div>    
                        <div className="back flex justify-center items-center">
                          <img src={IMGURL + element.img_2} style={{maxHeight: '340px', maxWidth: '300px'}}/>                  
                        </div>   
                      </div> 
                        
                      </div> : <div className="flex justify-center items-center px-10">
                      <PhotoIcon className='text-gray-300 h-64 items-center '/>
                    </div>}
                </div>
              </div>
              <div className="w-full py-4.2 md:ml-7 md:mt-0 md:w-auto md:max-w-[632px] md:py-4">
                <div className="hidden md:block">
                  <div>
                    <h2 className="font-bold text-2xl"
                      style={{
                        color: element.titleColor,
                        fontWeight: element.titleBold,
                        fontStyle: element.titleStyle
                      }}
                    >
                      {element.title}
                    </h2>
                    <p className="mt-1 text-base leading-5" style={{
                        color: element.subtitleColor,
                        fontWeight: element.subtitleBold,
                        fontStyle: element.subtitleStyle
                      }}>
                      {element.subtitle}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col flex-nowrap md:mt-6 md:flex-row text-white">
                {element.details && element.details.map((detail, dindex) => (
                  <div className="mt-6 flex items-start first:mt-0 md:mr-6 md:mt-0 md:block md:flex-1 md:justify-between md:last:mr-0 ">
                    <span className="min-w-[106px] text-base md:whitespace-nowrap md:text-lg" style={{
                        color: detail.titleColor,
                        fontWeight: detail.titleBold,
                        fontStyle: detail.titleStyle
                      }}>
                      {detail.title}
                    </span>
                    <div className="rich-text-editor bodySmall ml-2 md:mt-1 md:ml-0">
                      <p style={{
                        color: detail.subtitleColor,
                        fontWeight: detail.subtitleBold,
                        fontStyle: detail.subtitleStyle
                      }}>{detail.subtitle}</p>
                    </div>
                  </div>
                ))}
                </div>
                <hr className="my-6 hidden md:block" />
                <div className="mt-10 block md:mt-0 md:flex gap-x-6 flex justify-center items-center md:justify-start">
                  <Link to={element.buttonUrl} className="rounded-lg px-3.5 py-3 "
                      style={{ 
                        backgroundColor: element.buttonBg,
                      }}>
                        <span style={{ 
                        color: element.buttonTitleColor,
                        fontWeight: element.buttonTitleBold,
                        fontStyle: element.buttonTitleStyle,
                        textTransform: element.buttonTitleTransform
                      }}>{element.buttonTitle}</span>
                  </Link>
                  <Link to={element.buttonUrl2} className="rounded-lg px-3.5 py-3">
                        <span className="flex items-center gap-1" style={{ 
                        color: element.buttonTitleColor2,
                        fontWeight: element.buttonTitleBold2,
                        fontStyle: element.buttonTitleStyle2,
                        textTransform: element.buttonTitleTransform2
                      }}>{element.buttonTitle2}
                      <svg
                        width={16}
                        height={16}
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.4226 3.57741L10.8333 2.98816L9.65482 4.16667L10.2441 4.75593L14.6548 9.16667L3.33333 9.16667H2.5V10.8333L3.33333 10.8333L14.6548 10.8333L10.2441 15.2441L9.65482 15.8333L10.8333 17.0118L11.4226 16.4226L17.2559 10.5893C17.5814 10.2638 17.5814 9.73618 17.2559 9.41075L11.4226 3.57741Z"
                        />
                      </svg></span>
                      
                  </Link>
                </div>
              </div>
            </article>
          </div>
        ))}
      </div>)
    }
    if(component.name === '4x1'){
      return(<div className="py-6 mx-auto max-w-6xl  px-6 xl:px-0">
        <h1 class="text-3xl my-4 mb-8"
              style={{
                color: component.data.titleColor,
                fontWeight: component.data.titleBold,
                fontStyle: component.data.titleStyle,
              }}
            >{component.data.title}</h1>
          <section className="grid grid-cols-2 md:grid-cols-4 gap-5">
          {component.data.items && component.data.items.map((element, index) => (  
              <div className="col-span-1">
                <article className="duration-300 hover:scale-105 border-2 border-gray-200 rounded-2xl" style={{ backgroundColor: component.data.itemBg}}>
              <div
                className="h-64  p-3 flex-col justify-center  overflow-hidden  transition-all duration-300 ease-in-out gap-1 md:gap-4"
              >
                <div className="w-full flex justify-center"> 
                {getImageUrl(element.img_pre) ? 
                            <img
                              alt="Icon"
                              loading="lazy"
                              className='h-auto w-46 p-5'
                              src={getImageUrl(element.img_pre)}
                            />
                          : element.img ? 
                            <img
                              alt="Icon"
                              loading="lazy"
                              decoding="async"
                              className='h-auto w-46 p-5'
                              src={IMGURL + element.img}
                            />
                          : <PhotoIcon className='text-gray-300 h-20 items-center mb-10'/>} 
                </div>
                <div className="text-md leading-5 mb-2 mx-3.5 text-center font-bold">
                {element.title}
                </div>
              </div>
            </article>
            
              </div>
            ))} 
          </section>
      </div>)
    }
    if(component.name === '6x1'){
      return(<div className="py-6 mx-auto max-w-6xl  px-6 xl:px-0">
        <h1 class="text-3xl mb-6"
            style={{
              color: component.data.titleColor,
              fontWeight: component.data.titleBold,
              fontStyle: component.data.titleStyle,
            }}
          >{component.data.title}</h1>
        <section className="grid grid-cols-2 sm:grid-cols-4 lg:grid-cols-6 gap-3">
          {component.data.items && component.data.items.map((element, index) => (  
            <div className="col-span-1">
              <Link className="" to={element.url}>
                <article
                  className="transition-transform duration-300 will-change-transform hover:scale-105"
                >
                  <figure
                    className="relative grid overflow-hidden rounded-2xl transition-all duration-300 ease-in-out hover:!bg-[#2267B4] hover:!text-white h-[208px] gap-1 md:h-[214px] md:gap-4.2"
                    style={{ backgroundColor: "#F1F4F9", color: "#222" }}
                  >
                    <figcaption className="text-base leading-5 order-2 mb-4.2 mx-3.5">
                      {element.title}
                    </figcaption>
                    <div className="block w-full content-center">
                        {getImageUrl(element.img_pre) ? <div className="flex justify-center items-center ">
                          <img
                            alt="Icon"
                            loading="lazy"
                            className='w-auto p-3 self-end justify-self-center'
                            src={getImageUrl(element.img_pre)}
                          />
                        </div> : element.img ? <div className="flex justify-center items-center ">
                          <img
                            alt="Icon"
                            loading="lazy"
                            decoding="async"
                            className='h-32 w-auto p-3 '
                            src={IMGURL + element.img}
                          />
                        </div> : <div className="flex justify-center items-center">
                          <PhotoIcon className='text-gray-300 h-20 items-center '/>
                        </div> }                        
                      </div> 
                  </figure>
                </article>
              </Link>
            </div>
          ))}           
          
        </section>
      </div>)
    }
    if(component.name === 'news'){
      return(<div className='bg-blue-50 py-8 '>
        <div className=' pt-2  my-10 pb-12 mx-auto max-w-6xl px-6 xl:px-0'>
          <div className="container flex w-full justify-between items-center text-titleColor  my-10 ">
            <h2 className="font-bold text-2xl ">{GlobalText(19)}</h2>
            <div className="news-navigation flex justify-between gap-7 items-center ">
              <Link to={language === 'en' ? '/en/'+ newspage : language === 'ru' ? '/ru/'+ newspage : newspage }>
                <div className="text-base leading-5 flex items-center brt-text-medium brt-text-blue">
                  {GlobalText(104)}
                </div>
              </Link>
              <div className="flex items-center gap-3">
                <div className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-[50%] bg-white prev-article text-black p-3"
                  onClick={() => {
                    if (swiperRef.current && swiperRef.current.swiper) {
                      swiperRef.current.swiper.slidePrev();
                    }
                  }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={8}
                    height={14}
                    viewBox="0 0 8 14"
                    fill="none"
                  >
                    <path
                      d="M7 13.001L1 7.00098L7 1.00098"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-[50%] bg-white next-article text-black p-3" 
                  onClick={() => {
                      if (swiperRef.current && swiperRef.current.swiper) {
                        swiperRef.current.swiper.slideNext();
                      }
                    }}>
                  <svg
                    width={8}
                    height={14}
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 13.001L7 7.00098L1 1.00098"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-hidden swiper-initialized swiper-horizontal swiper-pointer-events w-full" >
              <Swiper
                className="brt-swiper " 
                ref={swiperRef}
                modules={[Virtual, Navigation, Pagination]}
                slidesPerView={1.1}
                centeredSlidesBounds={true}
                spaceBetween={30}
                navigation={false}
                breakpoints={{
                  400: {
                    slidesPerView: 1.1,
                    spaceBetween: 10,
                  },
                  768: {
                    slidesPerView: 1.5,
                    spaceBetween: 20,
                  },
                  1024: {
                    slidesPerView: 2.5,
                    spaceBetween: 30,
                  },
                }}
              >
                {news.map((element, index) => (
                  <SwiperSlide key={element.id} virtualIndex={index} onMouseEnter={() => setIsHovered(index)}
                  onMouseLeave={() => setIsHovered(99)} >
                    <article className='relative'>
                      <Link
                        className="bg-white group flex h-[340px] w-full flex-col justify-between rounded-3xl p-7 duration-300 hover:bg-blue-500 hover:text-white"
                        to={language === 'en' ? '/en/'+ newspage + '/' + element.url : language === 'ru' ? '/ru/'+ newspage + '/' + element.url : newspage + '/' + element.url}
                        onClick={() => {
                          localStorage.setItem('executed', false);
                        }}>
                        <div className="flex-col items-center">
                          <h2 className="font-bold text-xl leading-7 group-hover:text-white uppercase">
                            {language === 'en' ? element.title_en : language === 'ru' ? element.title_ru : element.title_tg}
                          </h2>
                          <p className="text-lg leading-6 group-hover:text-white mt-5">
                            {language === 'en' ? element.subtitle_en : language === 'ru' ? element.subtitle_ru : element.subtitle_tg}
                          </p>
                        </div>
                        <time className="text-base text-gray-500 group-hover:text-white">
                          {formatDate(element.created_at)}
                        </time>
                        <div className="absolute bottom-3 right-3 p-2">
                        <img src={isHovered === index ? LogoW : Logo} alt="BRT News" className='h-14 w-14 object-contain'/>
                        </div>
                      </Link>
                    </article>
                  </SwiperSlide>
                ))}
              </Swiper>
          </div>
        </div>
      </div>)
    }
    if(component.name === 'rate'){      
      return(<section className='pt-10'>
        <nav className="hide-scrollbar container grid grid-flow-col justify-start gap-5 overflow-x-auto text-lgx md:gap-7 mx-auto max-w-6xl px-6 xl:px-0">
          <div className={`cursor-pointer border-b-[3px] pb-2 hover:text-black hover:duration-150 ${
            calculatorsActive === 0 ? 'border-blue-600 brt-text-bold' : 'border-white text-gray-500 brt-text-regular'
          }`} onClick={() => setcalculatorsActive(0)}>
            <div className="bodyBig md:headRegular whitespace-nowrap">
              {GlobalText(88)}
            </div>
          </div>
          <div className={`cursor-pointer border-b-[3px] pb-2 hover:text-black hover:duration-150  ${
            calculatorsActive === 1 ? 'border-blue-600 brt-text-bold' : 'border-white text-gray-500 brt-text-regular'
          }`} onClick={() => setcalculatorsActive(1)}>
            <div className="bodyBig md:headRegular whitespace-nowrap" >
              {GlobalText(128)}
            </div>
          </div>
          <div className={`cursor-pointer border-b-[3px] pb-2 hover:text-black hover:duration-150  ${
            calculatorsActive === 2 ? 'border-blue-600 brt-text-bold' : 'border-white text-gray-500 brt-text-regular'
          }`} onClick={() => setcalculatorsActive(2)}>
            <div className="bodyBig md:headRegular whitespace-nowrap" >
              {GlobalText(129)}
            </div>
          </div>
        </nav>
        <div className="border-t border-gray-200 bg-blue-50 py-8 px-6 xl:px-0">
          <div className='mx-auto max-w-6xl'>
            {calculatorsActive === 0 && <div className="flex w-full flex-wrap items-center justify-between md:container ">              
              <section className="mx-3.5 w-full rounded-3xl bg-white px-4 py-6 sm:py-7 md:mx-0 lg:w-[400px] lg:px-6 my-5">
                <div className='flex justify-center'>
                  <div className="mb-7 flex w-fit rounded-[80px] bg-gray-200 p-2 ">
                    <button
                      type="button"
                      className={classNames(
                        !qBuyOrSell ? 'bg-gray-500 text-white' : 'text-gray-600',
                        'whitespace-nowrap rounded-full  leading-5 duration-150  hover:text-white py-2 px-4 hover:bg-gray-500'
                      )}
                      onClick={() => {
                        setqBuyOrSell(false)
                      }}
                    >
                      <p>{GlobalText(124)}</p>
                    </button>
                    <button
                      type="button"
                      className={classNames(
                        qBuyOrSell ? 'bg-gray-500 text-white' : 'text-gray-600',
                        'whitespace-nowrap rounded-full  leading-5 duration-150  hover:text-white py-2 px-4 hover:bg-gray-500'
                      )}
                      onClick={() => {
                        setqBuyOrSell(true)
                      }}
                    >
                      <p>{GlobalText(125)}</p>
                    </button>
                  </div>
                </div>
                <div>
                  <div className="flex w-full items-end justify-between  border-b border-grey pb-1">
                    <div className="flex max-w-[180px] flex-col sm:max-w-[270px]">
                      <span className="bodySmall md:bodyNormal mb-0.5 text-darkGrey">
                        { !qBuyOrSell ? GlobalText(126) : GlobalText(127) }
                      </span>
                      <input
                        id="convertto"
                        type="text"
                        ref={convertToRef}
                        autoComplete="off"
                        maxLength={10}
                        min={0}
                        className="border-none p-0 font-family-bold text-lgx leading-7 outline-none focus:ring-0 sm:text-2xl sm:leading-[38px]"
                        defaultValue={100}
                        onChange={(e) => {
                          let value = e.target.value;
                            // Замена запятой на точку
                            value = value.replace(',', '.');

                            // Проверка на валидность числа с не более чем двумя цифрами после точки
                            const isNumeric = /^\d*\.?\d{0,2}$/.test(value);

                            if (isNumeric || value === '') {
                              // Ограничение на 10 символов
                              if (value.length > 10) {
                                value = value.slice(0, 10);
                              }
                            } else {
                              // Удаляем последний символ, если это не число или больше двух цифр после точки
                              value = value.slice(0, -1);
                            }

                            // Установка нового значения input
                            e.target.value = value;
                            convertto()                                                    
                        }}
                      />
                    </div>
                    <div className="relative inline-block" data-headlessui-state="open">
                    <div className="w-full">
                      <button
                        className="border-gray-300 text-gray-700 hover:bg-gray-50 relative inline-flex w-full rounded-md border border-none bg-transparent font-medium focus:outline-none text-sm"
                        onClick={() => {
                          setqurbazshow(!qurbazshow)
                          setqurbbashow(false)
                        }}
                      >
                        <div className="flex items-center">
                          <div
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                            className="bodySmall mr-0.5"
                          >
                            {currencies[qurbaz].name}
                          </div>
                          <div style={{ color: "rgb(115, 120, 125)" }} className={qurbazshow ? "rotate-0" : "rotate-180"}>
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              className="transform -rotate-180 transition-transform duration-300 ease-in-out"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6 9L12 15L18 9"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="square"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                      </button>
                    </div>
                    <div
                      className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white text-black shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none transform scale-100 opacity-100"
                      hidden={!qurbazshow}
                    >
                      <div role="none">

                        {currencies.map((elem, index) => {
                          return(<div
                            className="text-gray-700 text-sm p-0.5 whitespace-nowrap cursor-pointer "
                            onClick={() => {
                              setqurbaz(index)
                              setqurbazshow(false)
                            }}>
                            <div className="flex items-center rounded-lg px-3 py-1 hover:bg-gray-100">
                              {elem.name}
                              <div className="ml-1">{qurbaz === index && 
                                <svg
                                  width={22}
                                  height={22}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M19.7335 6.31769C20.1103 6.72145 20.0885 7.35423 19.6848 7.73106L9.93476 16.8309L9.26473 17.4563L8.58349 16.8432L3.58349 12.3432C3.17298 11.9737 3.1397 11.3414 3.50916 10.9309C3.87861 10.5204 4.5109 10.4871 4.92141 10.8566L9.24017 14.7434L18.3201 6.26894C18.7239 5.89211 19.3567 5.91394 19.7335 6.31769Z"
                                    fill="#2267B4"
                                  />
                                </svg>
                              }</div>
                            </div>
                          </div>)
                        })}
                      </div>
                    </div>
                  </div>

                  </div>
                  <div className="mt-4.2 mb-1 text-center p-4"
                   >
                    <svg
                      onClick={() => {
                        const vf = qurbaz
                        setqurbaz(qurbba)
                        setqurbba(vf)
                     }}
                      width={24}
                      height={24}
                      viewBox="0 0 24 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="mx-auto cursor-pointer "
                    >
                      <path
                        d="M18.9999 11.8681V10.1409C18.9999 7.93173 17.209 6.14087 14.9999 6.14087H4.99991"
                        stroke="#222222"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M7.54536 8.68637L4.99991 6.14091L7.54536 3.59546"
                        stroke="#222222"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.99997 12.1317V13.859C4.99997 16.0681 6.79083 17.859 8.99997 17.859H19"
                        stroke="#222222"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M16.4545 15.3135L19 17.859L16.4545 20.4044"
                        stroke="#222222"
                        strokeWidth={2}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                  <div className="flex w-full items-end justify-between  border-b border-grey pb-1">
                    <div className="flex max-w-[180px] flex-col sm:max-w-[270px]">
                      <span className="bodySmall md:bodyNormal mb-0.5 text-darkGrey">
                      { qBuyOrSell ? GlobalText(126) : GlobalText(127) }
                      </span>
                      <input
                        id="convertfrom"
                        type="text"
                        autoComplete="off"
                        disabled
                        className="border-none p-0 font-family-bold text-lgx leading-7 outline-none focus:ring-0 sm:text-2xl sm:leading-[38px] disabled:bg-white"
                      />
                    </div>
                    <div className="relative inline-block" data-headlessui-state="">
                    <div className="w-full">
                      <button
                        className="border-gray-300 text-gray-700 hover:bg-gray-50 relative inline-flex w-full rounded-md border border-none bg-transparent font-medium focus:outline-none text-sm"
                        onClick={() => {
                          setqurbbashow(!qurbbashow)
                          setqurbazshow(false)
                        }}
                      >
                        <div className="flex items-center">
                          <div
                            role="button"
                            aria-haspopup="true"
                            aria-expanded="false"
                            className="bodySmall mr-0.5"
                          >
                            {currencies[qurbba].name}
                          </div>
                          <div style={{ color: "rgb(115, 120, 125)" }} className={qurbbashow ? "rotate-0" : "rotate-180"}>
                            <svg
                              width={24}
                              height={24}
                              viewBox="0 0 24 24"
                              fill="none"
                              className="transform -rotate-180 transition-transform duration-300 ease-in-out"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M6 9L12 15L18 9"
                                stroke="currentColor"
                                strokeWidth={2}
                                strokeLinecap="square"
                                strokeLinejoin="round"
                              />
                            </svg>
                          </div>
                        </div>
                      </button>
                    </div>
                    <div
                      className="absolute right-0 z-10 mt-2 origin-top-right rounded-md bg-white text-black shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none transform scale-100 opacity-100"
                      hidden={!qurbbashow}
                    >
                      <div role="none">

                        {currencies.map((elem, index) => {
                          return(<div
                            className="text-gray-700 text-sm p-0.5 whitespace-nowrap cursor-pointer "
                            onClick={() => {
                              setqurbba(index)
                              setqurbbashow(false)
                            }}>
                            <div className="flex items-center rounded-lg px-3 py-1 hover:bg-gray-100">
                              {elem.name}
                              <div className="ml-1">{qurbba === index && 
                                <svg
                                  width={22}
                                  height={22}
                                  viewBox="0 0 24 24"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                    d="M19.7335 6.31769C20.1103 6.72145 20.0885 7.35423 19.6848 7.73106L9.93476 16.8309L9.26473 17.4563L8.58349 16.8432L3.58349 12.3432C3.17298 11.9737 3.1397 11.3414 3.50916 10.9309C3.87861 10.5204 4.5109 10.4871 4.92141 10.8566L9.24017 14.7434L18.3201 6.26894C18.7239 5.89211 19.3567 5.91394 19.7335 6.31769Z"
                                    fill="#2267B4"
                                  />
                                </svg>
                              }</div>
                            </div>
                          </div>)
                        })}
                      </div>
                    </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="mb-5 w-full md:mb-0 md:mr-5 md:w-[552px]">
                <div className="hide-scrollbar flex overflow-x-auto px-3.5 md:max-w-[460px] md:flex-wrap md:px-0">
                  {calculators && calculators.map((el, ind) => {
                      return(<div className="bodySmall md:bodyNormal mr-1 last:mr-0 md:mb-3">
                        <button
                          type="button"
                          className={classNames(
                            thisrategroup === ind ? 'bg-gray-500 text-white' : 'bg-gray-200 text-gray-600',
                            'whitespace-nowrap rounded-full duration-150  hover:text-white py-2 px-4  hover:bg-gray-500'
                          )}
                          onClick={() => {
                            setthisrategroup(ind)
                          }}
                        >
                          <p>{language === 'en' ? el.title_en : language === 'ru' ? el.title_ru : el.title_tg }</p>
                        </button>
                      </div>)
                  })}
                  
                </div>
                <div className="mx-4 md:mx-0">
                  <table
                    aria-live="polite"
                    className="mt-5 mb-5 w-full sm:mt-6 sm:mb-6"
                  >
                    <thead>
                      <tr className="border-b border-grey text-gray-500">
                        <th
                          scope="col"
                          className="p-0 pb-3 text-left text-base font-normal leading-5"
                        >
                          {GlobalText(130)}
                        </th>
                        <th
                          scope="col"
                          className="p-0 pb-3 text-right text-base font-normal leading-5"
                        >
                          {GlobalText(131)}
                        </th>
                        <th
                          scope="col"
                          className="p-0 pb-3 text-right text-base font-normal leading-5"
                        >
                          {GlobalText(132)}
                        </th>
                      </tr>
                    </thead>
                    <tbody className="text-lg">
                      {calculators.length > 0 && calculators[thisrategroup].data && calculators[thisrategroup]['data'].map((el) => {
                        return(<tr>
                          <td className="w-[90px] p-0 pt-5 sm:pt-6 sm:pb-1 md:w-20.5">
                            <div className="bodyNormal md:bodyBig flex items-center text-gray-500">
                              1 {el.title}
                            </div>
                          </td>
                          <td className="headSubtitle md:head3 w-13.5 p-0 pt-5 text-right sm:pt-6 sm:pb-1 md:w-[200px]">
                            {el.value_buy}
                          </td>
                          <td className="headSubtitle md:head3 w-13.5 p-0 pt-5 text-right sm:pt-6 sm:pb-1 md:w-[200px]">
                            {el.value_sale}
                          </td>
                        </tr>)                        
                    })}
                    </tbody>
                  </table>
                </div>
                <div className="text-gray-500 text-lg font-mono">
                {cals_time && formattedDate(cals_time, language)} 
                </div>
              </section>
              
            </div>}

            {calculatorsActive === 1 && <div className="flex w-full flex-wrap items-center justify-between md:container ">              
              <aside className="mt-7 grid w-full gap-5 rounded-3xl bg-white px-4 py-6 md:mt-0 md:w-[384px] md:px-6"
              >
                <div>
                  <h3 className="text-base leading-5 text-gray-600">
                    {GlobalText(140)}
                  </h3>
                  <div className="text-2xl md:text-3xl mt-1 flex text-dark font-bold">
                    <p>
                      <span>{income.toFixed(2)} { language === 'en' ? 's.' : 'с.'}</span>
                    </p>
                  </div>
                </div>
                <div>
                  <h3 className="text-base leading-5 text-gray-600">
                  {GlobalText(142)}
                  </h3>
                  <div className="text-2xl md:text-3xl mt-1 flex text-dark font-bold">
                    <p>
                      <span>{monthlyincome.toFixed(2)} { language === 'en' ? 's.' : 'с.'}</span>
                    </p>
                  </div>
                </div>
                <div>
                  <h3 className="text-base leading-5 text-gray-600">
                  {GlobalText(136)}
                  </h3>
                  <div className="text-2xl md:text-3xl mt-1 flex text-dark font-bold">
                    <p>
                      <span>{deppercent} %</span>
                    </p>
                  </div>
                </div>
                <div>
                <label for="deptax" className="block text-md font-medium leading-5 text-gray-900 inline-flex items-center">
                  <input id="deptax" type="checkbox" checked={deptax} onChange={(e) => setdeptax(e.target.checked)} className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" /> 
                  <span className='ml-2'>{GlobalText(144)}</span>
                </label>
                </div>
                <Link to={ language === 'en' ? '/en/deposits' : language === 'ru' ? '/ru/deposits' : '/deposits'} className="rounded-lg px-3.5 py-3 brt-bg-blue w-full text-center font-bold text-white hover:brt-bg-blue" >
                  {GlobalText(141)}
                </Link>
              </aside>
              <section className="mb-5 w-full md:mb-0 md:mr-5 md:w-[552px]">
              <div className="relative mb-6">
                <div className="text-gray-500 text-md">
                  {GlobalText(143)}
                </div>
                <div className="text-gray-800 text-2xl font-bold">
                  {depsum} { language === 'en' ? 's.' : 'с.'}
                </div>
                  <input
                    type="range"
                    defaultValue={10000}
                    min={1000}
                    max={100000}
                    step={100}
                    onChange={(e) => {
                      setdepsum(e.target.value)
                    }}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                  />
              </div>
              <div className="relative mb-6">
                <div className="text-gray-500 text-md">
                  {GlobalText(136)}
                </div>
                <div className="text-gray-800 text-2xl font-bold">
                  {deppercent} %
                </div>
                  <input
                    type="range"
                    defaultValue={13}
                    min={13}
                    max={17}
                    onChange={(e) => {
                      setdeppercent(e.target.value)
                    }}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                  />
              </div>
              <div className="relative mb-6">
                <div className="text-gray-500 text-md">
                  {GlobalText(138)}
                </div>
                <div className="text-gray-800 text-2xl font-bold">
                  {depterm} { language === 'en' ? 'months' : language === 'ru' ? 'месяцев' : 'моҳ'}
                </div>
                  <input
                    type="range"
                    defaultValue={12}
                    min={12}
                    max={48}
                    onChange={(e) => {
                      setdepterm(e.target.value)
                    }}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                  />
              </div>

              </section>

            </div>}

            {calculatorsActive === 2 && <div className="flex w-full flex-wrap items-center justify-between md:container ">              
              <aside className="mt-7 grid w-full gap-5 rounded-3xl bg-white px-4 py-6 md:mt-0 md:w-[384px] md:px-6"
              >
                <div>
                  <h3 className="text-base leading-5 text-gray-600">
                    {GlobalText(134)}
                  </h3>
                  <div className="text-2xl md:text-3xl mt-1 flex text-dark font-bold">
                    <p>
                      <span>{overpayment.toFixed(2)} { language === 'en' ? 's.' : 'с.'}</span>
                    </p>
                  </div>
                </div>
                <div>
                  <h3 className="text-base leading-5 text-gray-600">
                  {GlobalText(135)}
                  </h3>
                  <div className="text-2xl md:text-3xl mt-1 flex text-dark font-bold">
                    <p>
                      <span>{monthly.toFixed(2)} { language === 'en' ? 's.' : 'с.'}</span>
                    </p>
                  </div>
                </div>
                <div>
                  <h3 className="text-base leading-5 text-gray-600">
                  {GlobalText(136)}
                  </h3>
                  <div className="text-2xl md:text-3xl mt-1 flex text-dark font-bold">
                    <p>
                      <span>{debtpercent} %</span>
                    </p>
                  </div>
                </div>
                <Link to={ language === 'en' ? '/en/credits' : language === 'ru' ? '/ru/credits' : '/credits'} className="rounded-lg px-3.5 py-3 brt-bg-blue w-full text-center font-bold text-white hover:brt-bg-blue" >
                  {GlobalText(139)}
                </Link>
              </aside>
              <section className="mb-5 w-full md:mb-0 md:mr-5 md:w-[552px]">
              <div className="relative mb-6">
                <div className="text-gray-500 text-md">
                  {GlobalText(137)}
                </div>
                <div className="text-gray-800 text-2xl font-bold">
                  {debtsum} { language === 'en' ? 's.' : 'с.'}
                </div>
                  <input
                    type="range"
                    defaultValue={1000}
                    min={1000}
                    max={100000}
                    step={100}
                    onChange={(e) => {
                      setdebtsum(e.target.value)
                    }}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                  />
              </div>
              <div className="relative mb-6">
                <div className="text-gray-500 text-md">
                  {GlobalText(136)}
                </div>
                <div className="text-gray-800 text-2xl font-bold">
                  {debtpercent} %
                </div>
                  <input
                    type="range"
                    defaultValue={20}
                    min={20}
                    max={30}
                    onChange={(e) => {
                      setdebtpercent(e.target.value)
                    }}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                  />
              </div>
              <div className="relative mb-6">
                <div className="text-gray-500 text-md">
                  {GlobalText(138)}
                </div>
                <div className="text-gray-800 text-2xl font-bold">
                  {debtterm} { language === 'en' ? 'months' : language === 'ru' ? 'месяцев' : 'моҳ'}
                </div>
                  <input
                    type="range"
                    defaultValue={12}
                    min={12}
                    max={48}
                    onChange={(e) => {
                      setdebtterm(e.target.value)
                    }}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                  />
              </div>

              </section>

            </div>}
          </div>
        </div>
        <div />
      </section>)
    }
    if(component.name === 'products'){      
      return(<div className="mx-auto max-w-6xl px-6 xl:px-0 py-6">        
        <h1 class="text-3xl "
            style={{
              color: component.data.titleColor,
              fontWeight: component.data.titleBold,
              fontStyle: component.data.titleStyle,
            }}
          >{component.data.title}</h1>
        {component.data.cards && component.data.cards.map((element, index) => (
          <div className="py-2">            
            <article className=" mb-4 flex flex-wrap items-center rounded-3xl px-5 py-8 md:px-0 md:py-10" style={{
              background: element.bgColor,
            }}>
              <div className="mt-4.2 md:mt-0">
                <div className="md:hidden">
                  <div>
                    <h2 className="font-bold text-2xl"
                        style={{
                          color: element.titleColor,
                          fontWeight: element.titleBold,
                          fontStyle: element.titleStyle
                        }}
                      >
                        {element.title}
                    </h2>
                    <p className="mt-1 text-base leading-5" style={{
                        color: element.subtitleColor,
                        fontWeight: element.subtitleBold,
                        fontStyle: element.subtitleStyle
                      }}>
                      {element.subtitle}
                    </p>
                  </div>
                </div>
                <div className='flex justify-center items-center'>
                {element.img ? <div className="flex justify-center items-center w-72">
                        <img
                          alt=""
                          className='max-w-full lg:max-w-md'
                          style={{width:'200px'}}
                          src={IMGURL + element.img}
                        />
                      </div> : <div className="flex justify-center items-center px-10">
                      <PhotoIcon className='text-gray-300 h-64 items-center '/>
                    </div>}
                </div>
              </div>
              <div className="w-full py-4.2 md:ml-10 md:mt-0 md:w-auto md:max-w-[632px] md:py-4">
                <div className="hidden md:block">
                  <div>
                    <h2 className="font-bold text-2xl"
                      style={{
                        color: element.titleColor,
                        fontWeight: element.titleBold,
                        fontStyle: element.titleStyle
                      }}
                    >
                      {element.title}
                    </h2>
                    <p className="mt-1 text-base leading-5" style={{
                        color: element.subtitleColor,
                        fontWeight: element.subtitleBold,
                        fontStyle: element.subtitleStyle
                      }}>
                      {element.subtitle}
                    </p>
                  </div>
                </div>
                <div className="flex flex-col flex-nowrap md:mt-6 md:flex-row text-white md:gap-x-24">
                {element.details && element.details.map((detail, dindex) => (
                  <div className="mt-6 flex items-center first:mt-0 md:mr-6 md:mt-0 md:block md:flex-1 md:justify-between md:last:mr-0 ">
                    <span className="min-w-[106px] text-base md:whitespace-nowrap md:text-md" style={{
                        color: detail.titleColor,
                        fontWeight: detail.titleBold,
                        fontStyle: detail.titleStyle
                      }}>
                      {detail.title}
                    </span>
                    <div className="rich-text-editor md:whitespace-nowrap text-lg ml-2 md:mt-1 md:ml-0">
                      <p style={{
                        color: detail.subtitleColor,
                        fontWeight: detail.subtitleBold,
                        fontStyle: detail.subtitleStyle
                      }}>{detail.subtitle}</p>
                    </div>
                  </div>
                ))}
                </div>
                <div className="my-10 hidden md:block" ></div> 
                <div className="mt-10 block md:mt-0 md:flex gap-x-6 flex justify-center items-center md:justify-start">
                  <Link to={element.buttonUrl} className="rounded-lg px-3.5 py-3 "
                      style={{ 
                        backgroundColor: element.buttonBg,
                      }}>
                        <span style={{ 
                        color: element.buttonTitleColor,
                        fontWeight: element.buttonTitleBold,
                        fontStyle: element.buttonTitleStyle,
                        textTransform: element.buttonTitleTransform
                      }}>{element.buttonTitle}</span>
                  </Link>
                  <Link to={element.buttonUrl2} className="rounded-lg px-3.5 py-3 ">
                        <span className="flex items-center gap-1" style={{ 
                        color: element.buttonTitleColor2,
                        fontWeight: element.buttonTitleBold2,
                        fontStyle: element.buttonTitleStyle2,
                        textTransform: element.buttonTitleTransform2
                      }}>{element.buttonTitle2}
                      <svg
                        width={16}
                        height={16}
                        viewBox="0 0 20 20"
                        fill="currentColor"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M11.4226 3.57741L10.8333 2.98816L9.65482 4.16667L10.2441 4.75593L14.6548 9.16667L3.33333 9.16667H2.5V10.8333L3.33333 10.8333L14.6548 10.8333L10.2441 15.2441L9.65482 15.8333L10.8333 17.0118L11.4226 16.4226L17.2559 10.5893C17.5814 10.2638 17.5814 9.73618 17.2559 9.41075L11.4226 3.57741Z"
                        />
                      </svg></span>
                  </Link>
                </div>
              </div>
            </article>
          </div>
        ))}
      </div>)
    }
    if(component.name === '3block'){
      return(<div className="py-5 mx-auto max-w-6xl px-6 xl:px-0">
        <h1 class="text-3xl my-4 mb-8"
              style={{
                color: component.data.titleColor,
                fontWeight: component.data.titleBold,
                fontStyle: component.data.titleStyle,
              }}
            >{component.data.title}</h1>
        <section className="my-5 ">
        <section className="grid gap-4 md:grid-cols-3">
          {component.data.items && component.data.items.map((element, index) => (  
              <a className="" href="#">
              <article className="transition-transform duration-300 will-change-transform hover:scale-105">
                <figure
                  className="relative grid overflow-hidden rounded-3xl md:h-[424px]"
                  style={{ backgroundColor: component.data.itemBg}}
                >
                  <header className="mx-5 mt-5 mb-3 self-start md:absolute md:mx-5 md:mb-0 md:mt-6">
                    <h2 className="text-xl mb-1 md:mb-3" style={{ fontWeight: component.data.itemBold, color: component.data.itemColor, fontStyle: component.data.itemStyle }}>
                    {element.title}
                    </h2>
                    <div className="rich-text-editor text-lg">
                      <p>
                      {element.subtitle}
                      </p>
                    </div>
                  </header>
                  <div className="self-end mb-5 mx-1 items-center">
                  {getImageUrl(element.img_pre) ? <div className="flex justify-center items-center ">
                              <img
                                alt="Icon"
                                loading="lazy"
                                width="250px"
                                height="auto"
                                decoding="async"
                                className="mx-auto"
                                style={{ color: "transparent" }}
                                src={getImageUrl(element.img_pre)}
                              />
                            </div> : element.img ? <div className="flex justify-center items-center ">
                              <img
                                alt="Icon"
                                loading="lazy"
                                width="250px"
                                height="auto"
                                decoding="async"
                                className="mx-auto"
                                style={{ color: "transparent" }}
                                src={IMGURL + element.img}
                              />
                            </div> : <div className="flex justify-center items-center">
                              <PhotoIcon className='text-gray-300 h-20 items-center '/>
                            </div> }  
                  </div>
                </figure>
              </article>
            </a>
            ))} 
          </section>
        </section>
      </div>)
    }
    if(component.name === '3block2'){
      return(<div className="py-5 mx-auto max-w-6xl px-6 xl:px-0">
        <h1 class="text-3xl my-4 mb-8"
              style={{
                color: component.data.titleColor,
                fontWeight: component.data.titleBold,
                fontStyle: component.data.titleStyle,
              }}
            >{component.data.title}</h1>
        <div class="mb-5 grid gap-4.2 md:mb-6 md:grid-cols-3">
          {component.data.items && component.data.items.map((element, index) => ( 
                <div class="flex justify-center md:flex-col md:justify-start md:px-5 my-4 md:pb-7 md:pt-4.2 ">
                <div class="font-bold text-[72px] leading-[92px] text-blue-600 md:text-[96px] md:leading-[123px] mr-12">{index + 1}</div>
                <div class="max-w-full md:mt-3 md:max-w-[296px]">
                  <div class="text-xl font-bold mb-3">
                  {element.title}
                  </div>
                  <div class="text-base leading-5">
                  {element.subtitle}
                  </div>
                </div>
              </div>
              ))} 
          </div>
      </div>)
    }
    if(component.name === 'sender'){
      return(<div className="py-5 mx-auto max-w-6xl px-6 xl:px-0" id="getBlock" ref={blockRef}>
          <div
          className="overflow-hidden rounded-2xl py-6 px-4 md:rounded-3xl md:px-10 md:py-14"
          style={{ color: "rgb(255, 255, 255)", background: "#1C2720" }}
        >
          <div className="flex flex-wrap justify-between items-center">
            <div className="text-center md:w-[360px] md:text-left mb-10 md:mx-8">
              <h2 className="text-2xl mb-3 text-white font-bold">
              {component.data.title}
              </h2>
              <p className="text-md mb-4.2 text-white">
              {component.data.subtitle}
              </p>
            </div>
            <form
              className="w-full md:w-[unset] md:mx-8"
              aria-labelledby="form-description"
              onSubmit={(e) => {
                e.preventDefault() 
                setLoading(true)
                axios.post(APIURL + 'addrequests', {
                  'name': value,
                  'phone': value2,
                  'senderpage': component.data.senderpage,
                }, {
                  headers: {
                      'Content-Type': 'application/json'
                  }
                })
                .then((response) => {
                  if(response.status === 200){       
                    setLoading(false)
                    setValue('')
                    setValue2('')
                    toast.success(succesText)  
                  }
                })
                .catch(function (error) {   
                  setLoading(false)          
                })                
                  
              }}
            >
              <div className="mb-5 md:w-[456px]">
                <div>
                  <label htmlFor="full-name">
                    <div className="text-small md:bodyNormal text-white pb-2">                      
                      { language === "en" && "First and last name" }
                      { language === "ru" && "Имя и фамилия" }
                      { language === "tg" && "Ному насаб" }
                    </div>
                  </label>
                  <input
                    type="text"
                    id="full-name"
                    name="fullName"
                    autoComplete="off"
                    required
                    placeholder={ language === "en" ? "Your first and last name" : language === "ru" ? "Ваше имя и фамилия" : "Ному насаби шумо" }
                    minLength={2}
                    maxLength={90}
                    value={value}
                    onInput={handleInput}
                    className="mt-0.5 w-full rounded-lg border-none p-0 px-3.5 py-3 text-lg leading-7 text-black placeholder-darkGrey outline-none focus:ring-0 md:py-4"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="mb-5 md:w-[456px]">
                <div>
                  <label htmlFor="phone-number">
                    <div className="text-small md:bodyNormal text-white pb-2">
                      { language === "en" ? "Phone number" : language === "ru" ? "Номер телефона" : "Рақами телефон" }
                    </div>
                  </label>
                  <input
                    type="text"
                    id="phone-number"
                    name="phone"
                    autoComplete="off"
                    required
                    placeholder={ language === "en" ? "Your phone number" : language === "ru" ? "Ваш номер телефона" : "Рақами телефони шумо" }
                    minLength={9}
                    maxLength={15}
                    value={value2}
                    onInput={handleInput2}
                    className="mt-0.5 w-full rounded-lg border-none p-0 px-3.5 py-3 text-lg leading-7 text-black placeholder-darkGrey outline-none focus:ring-0 md:py-4"
                    defaultValue=""
                  />
                </div>
              </div>
              <div className="text-center md:text-left">
                <div className="flex flex-wrap items-center mt-5 mb-3">
                  <button
                    type="submit"
                    disabled={loading}
                    className="group grid grid-flow-col items-center justify-center outline-none duration-150 py-3 px-5 font-bold text-lg leading-5.8 gap-2 bg-blue-600 text-white hover:bg-blue-700  w-full md:w-auto rounded-lg"
                  >
                    {loading 
                        ? (language === "en" ? "Send ..." : language === "ru" ? "Отправить ..." : "Равон кардан ...")
                        : (language === "en" ? "Send" : language === "ru" ? "Отправить" : "Равон кардан")
                    }
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>)
    }
    if(component.name === 'slideshow'){
      return(<div className="py-5 mx-auto max-w-6xl px-6 xl:px-0">
        <>
        <Swiper 
            slidesPerView={1}
            spaceBetween={30}
            loop={true}
            speed={500}
            autoplay={{
              delay: 4000,
              disableOnInteraction: true,          
            }}
            navigation={false}
            pagination={{
              clickable: true
            }}
            
            modules={[Autoplay, EffectFade, Pagination, Navigation]}
            className="swiper max-w-full "
            effect={'fade'}
          >
            {component.data.slides && component.data.slides.map((element, index) => {   
            return(
              <SwiperSlide className="w-full">
              <div className="relative w-full h-[300px] md:h-[450px]"> 
              {getImageUrl(element.img_pre) ? <img
                              className='absolute inset-0 w-full h-full object-cover rounded-xl md:rounded-3xl'
                              src={getImageUrl(element.img_pre)}
                            /> : element.img ? <img
                            className='absolute inset-0 w-full h-full object-cover rounded-xl md:rounded-3xl'
                            src={IMGURL + element.img}
                          /> : <div className="flex justify-center items-center">
                            <PhotoIcon className='text-gray-300 h-32 items-center '/>
                          </div> } 
              </div>
            </SwiperSlide>)})}

          </Swiper>
        </>
      </div>
      )
    }
    if(component.name === 'blockimg'){
      return(<div className=" mx-auto max-w-6xl px-6 xl:px-0">
        {component.data.items && component.data.items.map((element, index) => (
            <div className="flex flex-wrap md:flex-nowrap items-center justify-between py-8">          
            <div className={`order-1 ${index % 2 === 0 ? '' : 'md:order-2'}`}>
              <div className="text-xl md:text-2xl mb-3 md:mb-5 " style={{color: element.titleColor, fontWeight: element.titleBold, fontStyle: element.titleStyle}}>{element.title}</div>
              <div className="text-base leading-5">
                <div className="rich-text-editor leading-5 text-lg text-gray-700">
                  <p>{element.subtitle}</p>
                </div>
              </div>
            </div>
            {getImageUrl(element.img_pre) ? <img
                  loading="lazy"
                  width={250}
                  height={'auto'}
                  decoding="async"
                  className={`order-2 mx-auto max-w-[178px] md:mx-[unset] md:max-w-[unset] ml-0 md:ml-10 ${index % 2 === 0 ? '' : 'md:order-1'}`}
                  style={{ color: "transparent" }}
                  src={getImageUrl(element.img_pre)}
                /> : element.img ? <img
                  loading="lazy"
                  width={250}
                  height={'auto'}
                  decoding="async"
                  className={`order-2 mx-auto max-w-[178px] md:mx-[unset] md:max-w-[unset] ml-0 md:ml-10 ${index % 2 === 0 ? '' : 'md:order-1'}`}
                  style={{ color: "transparent" }}
                  src={IMGURL + element.img}
                /> : <PhotoIcon className='text-gray-300 h-20 items-center '/>}            
          </div> 
          ))}    
      </div>)
    }
    if(component.name === 'numbers'){
      return(<div className="bg-blue-50 my-6">
        <div className="mx-auto max-w-6xl px-6 xl:px-0 py-16 md:py-20">
          <div className="container">
          <h1 class="text-3xl mb-10"
            style={{
              color: component.data.titleColor,
              fontWeight: component.data.titleBold,
              fontStyle: component.data.titleStyle,
            }}
          >{component.data.title}</h1>
            <div className="mt-5 grid gap-5 md:grid-cols-2 md:gap-7 md:gap-x-10">
            {component.data.items && component.data.items.map((element, index) => (  
            <div>
            <div className="text-3xl leading-[51px]" style={{
              color: component.data.itemColor,
              fontWeight: component.data.itemBold,
              fontStyle: component.data.itemStyle,
            }}>
            {element.title}
            </div>
            <div className="text-base leading-5" style={{
              color: component.data.subitemColor,
              fontWeight: component.data.subitemBold,
              fontStyle: component.data.subitemStyle,
            }}>
            {element.subtitle}
            </div>
          </div>
          ))}   
              
            </div>
          </div>
        </div>
    
      </div>)
    }    
    if(component.name === 'adress'){
      return(<section className="mx-auto max-w-6xl px-6 xl:px-0 my-6">
            <h1 class="text-3xl font-bold my-6"
            style={{
              color: component.data.titleColor,
              fontWeight: component.data.titleBold,
              fontStyle: component.data.titleStyle,
            }}
          >{component.data.title} </h1>

            <nav className="hide-scrollbar w-full grid grid-flow-col justify-start gap-5 overflow-x-auto text-lgx md:gap-7 mt-3 border-b border-gray-200 mb-6">
                {component.data.items && component.data.items.map((element, index) => (
                  <div key={index} className={`cursor-pointer border-b-[3px] pb-2  hover:text-black hover:duration-150 ${
                    columnActive === index ? 'border-blue-600 brt-text-bold' : 'border-white text-gray-500 brt-text-regular'
                  }`} onClick={() => setColumnActive(index)}>
                    <div className="bodyBig md:headRegular whitespace-nowrap">                    
                      { element.title}
                    </div>
                  </div>
                ))}
            </nav>
            
            {columnActive === 0 && <div className="w-full">
              <div className="rounded-3xl px-4 py-5 md:px-11 md:py-10 border border-gray-200 ">
                <div className="text-2xl md:text-3xl mb-5 md:mb-0 font-bold">
                    {component.data.items[0].items[0].title}
                </div>
                <hr className="my-5 hidden text-[#DFE4E9] md:block" />
                <div className="flex flex-wrap justify-between">
                  <div className="mb-5 max-w-[380px] md:mb-4.2">
                    <div className="text-sm uppercase leading-4.5 tracking-[1.05px] mb-1 text-gray-500">
                    {GlobalText(70)}
                    </div>
                    <div className="text-base leading-5">
                    {component.data.items[0].items[0].address}
                    </div>
                  </div>
                  <div className="mb-5 md:mb-4.2 md:min-w-[456px]">
                    <div className="text-sm uppercase leading-4.5 tracking-[1.05px] mb-1 text-gray-500">
                    {GlobalText(69)}
                    </div>
                    <div className="mb-1 flex">
                      <a href="tel:900">
                        <div className="headSubtitle md:head3">
                          <div className="rich-text-editor">
                            <p>
                              <strong>{component.data.items[0].items[0].phone}</strong>
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <a href={`mailto:${component.data.items[0].items[0].email}`}>
                      <div className="text-lg leading-6">{component.data.items[0].items[0].email}</div>
                    </a>
                  </div>
                  <div className="mb-5 max-w-[456px] md:mb-0">
                    <div className="text-sm uppercase leading-4.5 tracking-[1.05px] mb-1 text-gray-500">
                    {GlobalText(169)}
                    </div>
                    {component.data.items[0].items[0].workingMode && component.data.items[0].items[0].workingMode.map((element2, index2) => (
                      <div className=" text-base leading-5 mb-1.5 font-sanse">                      
                      {element2.title}
                    </div>
                    ))
                    }
                  </div>
                  <div className="max-w-[456px] md:mt-2">
                    <div className="text-sm uppercase leading-4.5 tracking-[1.05px] mb-1 text-gray-500">
                    {GlobalText(170)}
                    </div>
                    <div className="text-base leading-5">                      
                    {component.data.items[0].items[0].services}
                    </div>
                  </div>
                </div>
                <div className="">
                <div
                    style={{
                      marginTop: 32,
                      height: 400,
                      width: "100%",
                      borderRadius: 16,
                      position: "relative",
                      overflow: "hidden"
                    }}
                  >
                    <iframe src={component.data.items[0].items[0].map} width="100%" height="100%" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  </div>
                </div>
              </div>
              <div className="text-2xl md:text-3xl mt-7  font-bold pb-5">
                {component.data.items[0].items[1].title}
              </div>
              <div class="hide-scrollbar  flex space-x-1 overflow-auto my-4">
                {component.data.items[0].items[1].items && component.data.items[0].items[1].items.map((element4, index4) => (
                  <button type="button" 
                  className={classNames(
                    adresssectionactive === index4  ? 'bg-gray-800 text-white' : 'bg-gray-100 text-gray-500',
                    ' whitespace-nowrap rounded-full py-2.5 px-4 '
                  )}
                  onClick={() => setadresssectionactive(index4)}>
                  <p>
                    {element4.mintaqa}
                  </p>
                </button> 
                ))}             
              </div>
              {component.data.items[0].items[1].items && component.data.items[0].items[1].items.map((element4, index4) => (
                  adresssectionactive === index4 && component.data.items[0].items[1].items[index4].items && component.data.items[0].items[1].items[index4].items.map((element5, index5) => (<div className="border-b border-gray-200 my-2">
                  <button
                    onClick={() => toggleAccordion(index5)}
                    className="w-full text-left "
                    aria-expanded={openSection === index5}
                    aria-controls={'accordion-flush-body-' + index5}
                  >
                    <div
              className="flex items-center justify-between w-full py-5 font-medium rtl:text-right text-gray-500  dark:border-gray-700 dark:text-gray-400 gap-3"
            >
              <div>
                <div className="text-xl font-bold text-gray-800 mb-1">
                  {element5.title}
                </div>
              </div>
              <svg
                data-accordion-icon=""
                className={ openSection === 0 ? "w-3 h-3 shrink-0 rotate-0" : 'w-3 h-3 shrink-0 rotate-180'}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5 5 1 1 5"
                />
              </svg>
            </div>
                  </button>
                  <div
                    id={'accordion-flush-body-' + index5}
                    className={openSection === index5 ? 'block' : 'hidden'}
                    aria-labelledby={`accordion-flush-heading-${index5}`}
                  >
                    <div className="my-2">
                    <div className="flex flex-wrap justify-between">
                  <div className="mb-5 max-w-[380px] md:mb-4.2">
                    <div className="text-sm uppercase leading-4.5 tracking-[1.05px] mb-1 text-gray-500">
                    {GlobalText(70)}
                    </div>
                    <div className="text-base leading-5">
                    {element5.address}
                    </div>
                  </div>
                  <div className="mb-5 md:mb-4.2 md:min-w-[456px]">
                    <div className="text-sm uppercase leading-4.5 tracking-[1.05px] mb-1 text-gray-500">
                    {GlobalText(69)}
                    </div>
                    <div className="mb-1 flex">
                      <a href="tel:900">
                        <div className="headSubtitle md:head3">
                          <div className="rich-text-editor">
                            <p>
                              <strong>{element5.phone}</strong>
                            </p>
                          </div>
                        </div>
                      </a>
                    </div>
                    <a href={`mailto:${element5.email}`}>
                      <div className="text-lg leading-6">{element5.email}</div>
                    </a>
                  </div>
                  <div className="mb-5 max-w-[456px] md:mb-0">
                    <div className="text-sm uppercase leading-4.5 tracking-[1.05px] mb-1 text-gray-500">
                    {GlobalText(169)}
                    </div>
                    {element5.workingMode && element5.workingMode.map((element2, index2) => (
                      <div className=" text-base leading-5 mb-1.5 font-sanse">                      
                      {element2.title}
                    </div>
                    ))
                    }
                  </div>
                  <div className="max-w-[456px] md:mt-2">
                    <div className="text-sm uppercase leading-4.5 tracking-[1.05px] mb-1 text-gray-500">
                    {GlobalText(170)}
                    </div>
                    <div className="text-base leading-5">                      
                    {element5.services}
                    </div>
                  </div>
                </div>
                <div className="">
                <div
                    style={{
                      marginTop: 32,
                      height: 400,
                      width: "100%",
                      borderRadius: 16,
                      position: "relative",
                      overflow: "hidden"
                    }}
                  >
                    <iframe src={element5.map} width="100%" height="100%" style={{border:0}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                  </div>
                </div>
                    </div>
                  </div>
                </div>))
                ))} 
              
            </div>}

            { columnActive === 1 && <div class="">
              <div class="hide-scrollbar  flex space-x-1 overflow-auto my-4">
              {component.data.items[1].items && component.data.items[1].items.map((element4, index4) => (
                  <button type="button" 
                  className={classNames(
                    atmsactive === index4  ? 'bg-gray-800 text-white' : 'bg-gray-100 text-gray-500',
                    ' whitespace-nowrap rounded-full py-2.5 px-4 '
                  )}
                  onClick={() => setatmsactive(index4)}>
                  <p>
                    {element4.title}
                  </p>
                </button> 
                ))}                              
              </div>

              <div className="w-full">
                <div className="rounded-3xl bg-white py-5 ">
                  <div className="grid items-center justify-between gap-0.5 border-b border-b-[#DFE4E9] py-3 md:py-5 grid-flow-col">
                    <div className="text-gray-400 text-xl">                    
                    { GlobalText(70).toLocaleUpperCase() }
                    </div>
                    <div className="md:w-[288px]">
                      <div className="bodyNormal md:headRegular">
                        <div className="text-gray-400 text-right ">
                        { GlobalText(177).toLocaleUpperCase() }
                        </div>
                      </div>
                    </div>
                  </div>
                  {component.data.items[1].items[atmsactive].items && component.data.items[1].items[atmsactive].items.map((element5, index5) => (
                    <div className="grid items-center justify-between gap-0.5 border-b border-b-[#DFE4E9] py-3 md:py-5 grid-flow-col">
                    <div className="bodySmall md:bodyBig text-darkGrey">                    
                    {element5.address}
                    <br/>
                              
                        {GlobalText(176) + ': ' + element5.landmark}
                        </div>
                        <div className="md:w-[288px]">
                          <div className="bodyNormal md:headRegular">
                            <div className="text-gray-500 text-right">
                            {element5.ours}
                        </div>
                      </div>
                    </div>
                  </div>
                  ))}    


                </div>
                <div className="">
                <>{showMap()}</>
            </div>
              </div>


            </div>}

            { columnActive === 2 && <div class="">
              <div className="w-full">
                <div className="rounded-3xl bg-white py-5 ">
                {component.data.items[2].items && component.data.items[2].items.map((element4) => (
                  <div className="grid items-center justify-between gap-0.5 border-b border-b-[#DFE4E9] py-3 md:py-5 lg:grid-flow-col">
                  <div className="bodySmall md:bodyBig text-darkGrey">                    
                    {element4.name}
                  </div>
                  <div className="md:w-[288px]">
                    <div className="bodyNormal md:headRegular">
                      <div className="rich-text-editor font-bold">
                      {element4.value}
                      </div>
                    </div>
                  </div>
                </div>
                ))} 
                </div>
              </div>

            </div>}


            {columnActive === 3 && <div className="">
              {component.data.items[3].items && component.data.items[3].items.map((element4) => (
                  <div className="flex flex-wrap justify-between border-b border-gray-200">
                  <div className="mb-5 max-w-[380px] md:mb-4.2">
                    <div className="text-lg leading-4.5 mb-2 text-gray-800 font-bold">
                    {element4.title}
                    </div>
                    <div className="text-base leading-5 text-gray-600">
                    {element4.adress}
                    </div>
                  </div>

                  <div className="mb-5 md:mb-4.2 md:min-w-[456px] ">
                  {element4.items && element4.items.map((element5) => (                      
                      <div className="mb-7">
                        <div className="text-lg leading-4.5 mb-1 text-gray-800 font-bold">                          
                          {element5.name}
                        </div>
                        <div className="text-base leading-5 text-gray-600 mb-3 ">
                        {element5.job}
                        </div>
                        <div className="flex items-center">
                          <div className="text-base leading-5 text-gray-600 flex mr-5 ">
                            <FaRegCalendar className="mr-2"/> 
                            {element5.days}
                          </div>
                          <div className=" text-base leading-5 text-gray-600 flex items-center font-sanse">
                            <MdAccessTime className="mr-2 "/> 
                            <span className="font-mono">{element5.ours}</span>
                          </div>
                        </div>
                      </div>
                  
                    ))} </div>

                </div>
                ))} 

            </div>}

      </section>)
    }
    if(component.name === 'documents'){
      
      return(<div className="mx-auto max-w-6xl px-6 xl:px-0 py-10">
          <h1 class="text-3xl mb-5"
            style={{
              color: component.data.titleColor,
              fontWeight: component.data.titleBold,
              fontStyle: component.data.titleStyle,
            }}
          >{component.data.title}</h1>
          <div className="md:flex">
              <ul className="flex-column space-y space-y-4 text-sm font-medium text-gray-500 dark:text-gray-400 md:me-4 mb-4 md:mb-0">
                {component.data.items && component.data.items.map((element, index) => (
                  <li>
                  <a
                    onClick={() => setColumnActive(index)}
                    className={`cursor-pointer inline-flex items-center px-4 py-3 rounded-lg w-full uppercase ${
                      columnActive === index ? 'active text-white bg-blue-600' : 'hover:text-gray-900 bg-gray-50 hover:bg-gray-100'
                    }`}
                  >
                    {element.title}
                  </a>
                </li>                  
                ))}
              </ul>              
              <div className="p-6 bg-gray-50 text-medium text-gray-500 dark:text-gray-400 dark:bg-gray-800 rounded-lg w-full">
                {component.data.items[columnActive].items && component.data.items[columnActive].items.map((element, index) => (
                  <div className="w-full flex items-center mb-3">
                  <FaRegFileAlt  className='h-8 w-8 mr-5 text-blue-500'/>
                  <a href={FILESURL + element.url} className="block w-full text-lg text-black hover:text-blue-500" >{element.title}</a>
                </div>
                ))}
              
              </div>
            </div>
      </div>)
    }
    if(component.name === 'tarifs'){
      return(<div className="mx-auto max-w-6xl px-6 xl:px-0 my-16">
            <h1 class="text-3xl font-bold my-6"
            style={{
              color: component.data.titleColor,
              fontWeight: component.data.titleBold,
              fontStyle: component.data.titleStyle,
            }}
          >{component.data.title}</h1>

        <div class="">
        <nav className="hide-scrollbar w-full grid grid-flow-col justify-start gap-5 overflow-x-auto text-lgx md:gap-7 mt-3 border-b border-gray-200">
              {component.data.items && component.data.items.map((element, index) => (
                <div key={index} className={`cursor-pointer border-b-[3px] pb-2  hover:text-black hover:duration-150 ${
                  columnActive === index ? 'border-blue-600 brt-text-bold' : 'border-white text-gray-500 brt-text-regular'
                }`} onClick={() => setColumnActive(index)}>
                  <div className="bodyBig md:headRegular whitespace-nowrap">                    
                    { element.title}
                  </div>
                </div>
              ))}
          </nav>
          <div className="w-full">
                  <div className="rounded-3xl bg-white py-5 ">
                    {component.data.items[columnActive].type === '2' && <div className="grid items-center justify-between gap-0.5 border-b border-b-[#DFE4E9] py-3 md:py-5 grid-flow-col">
                    <div className="bodySmall md:bodyBig text-darkGrey">                      
                    </div>
                    <div className="flex gap-5 justify-between md:w-[500px]">
                    <div className="">
                      <div className="bodyNormal md:headRegular">
                        <div className="rich-text-editor text-gray-500">
                        TJS
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="bodyNormal md:headRegular">
                        <div className="rich-text-editor text-gray-500">
                        RUB
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="bodyNormal md:headRegular">
                        <div className="rich-text-editor text-gray-500">
                        USD
                        </div>
                      </div>
                    </div>
                    </div>
                  </div>}
            {component.data.items[columnActive].items && component.data.items[columnActive].items.map((element, index) => (
              <div className="grid items-center justify-between gap-0.5 border-b border-b-[#DFE4E9] py-3 md:py-5 lg:grid-flow-col">
                {<div className="bodySmall md:bodyBig text-darkGrey">
                  {element.title}
                </div>}
                {component.data.items[columnActive].type === '1' && <div className="md:w-[500px]">
                  <div className="bodyNormal md:headRegular">
                    <div className="rich-text-editor ">
                      {element.subtitle}
                      <br/>
                  <span className="text-gray-500">
                  {element.subtitle2}
                  </span>
                    </div>
                  </div>
                </div>}
                {component.data.items[columnActive].type === '2' && <div className="flex gap-5 justify-between md:w-[500px]">
                    <div className="text-center">
                      <div className="bodyNormal md:headRegular text-center">
                        <div className="rich-text-editor text-gray-800 text-center">
                        {element.columns.tjs}
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="bodyNormal md:headRegular">
                        <div className="rich-text-editor text-gray-800 text-center">
                        {element.columns.rub}
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="bodyNormal md:headRegular">
                        <div className="rich-text-editor text-gray-800">
                        {element.columns.usd}
                        </div>
                      </div>
                    </div>
                    </div>}

              </div>
              ))}</div>
                  </div>
            
            </div>
      </div>)
    }
    if(component.name === 'faq'){
      return(<div className="mx-auto max-w-6xl px-6 xl:px-0 my-6 pt-5">
            <h1 class="text-3xl font-bold my-4"
            style={{
              color: component.data.titleColor,
              fontWeight: component.data.titleBold,
              fontStyle: component.data.titleStyle,
            }}
          >{component.data.title}</h1>

        <div class="">
              <div>
              {component.data.items && component.data.items.map((element, index) => (
                <div key={element.qu} className="border-b border-gray-200 my-2">
                  <button
                    onClick={() => toggleAccordion(index)}
                    className="w-full text-left "
                    aria-expanded={openSection === index}
                    aria-controls={element.qu}
                  >
                    <div
              className="flex items-center justify-between w-full py-5 font-medium rtl:text-right text-gray-500  dark:border-gray-700 dark:text-gray-400 gap-3"
            >
              <div>
                <div className={openSection === index ? "text-xl text-blue-500 " : 'text-xl text-gray-800 '}>
                {element.qu}
                </div>
              </div>
              <svg
                data-accordion-icon=""
                className={ openSection === index ? "w-3 h-3 shrink-0 rotate-0" : 'w-3 h-3 shrink-0 rotate-180'}
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 10 6"
              >
                <path
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 5 5 1 1 5"
                />
              </svg>
            </div> 
                  </button>
                  <div
                    id={element.qu}
                    className={openSection === index ? 'block' : 'hidden'}
                    aria-labelledby={`accordion-flush-heading-${index}`}
                  >
                    <div className="my-2">
                    <div className="pb-5">{element.an}</div>
                      
                    </div>
                  </div>
                </div>
              ))}
          </div>
            </div>
      </div>)
    }
    if(component.name === 'credit_cal'){
      
      return(<section className='pt-10'>
        <div className="border-t border-gray-200 bg-blue-50 py-8 px-6 xl:px-0">
          <div className='mx-auto max-w-6xl py-6'>
          <div className="text-2xl md:text-3xl  md:mb-0 font-bold pb-5">              
              { GlobalText(129) }
              </div>
            <div className="flex w-full flex-wrap items-center justify-between md:container ">              
              <aside className="mt-7 grid w-full gap-5 rounded-3xl bg-white px-4 py-6 md:mt-0 md:w-[384px] md:px-6"
              >
                <div>
                  <h3 className="text-base leading-5 text-gray-600">
                    {GlobalText(134)}
                  </h3>
                  <div className="text-2xl md:text-3xl mt-1 flex text-dark font-bold">
                    <p>
                      <span>{overpayment.toFixed(2)} { language === 'en' ? 's.' : 'с.'}</span>
                    </p>
                  </div>
                </div>
                <div>
                  <h3 className="text-base leading-5 text-gray-600">
                  {GlobalText(135)}
                  </h3>
                  <div className="text-2xl md:text-3xl mt-1 flex text-dark font-bold">
                    <p>
                      <span>{monthly.toFixed(2)} { language === 'en' ? 's.' : 'с.'}</span>
                    </p>
                  </div>
                </div>
                <div>
                  <h3 className="text-base leading-5 text-gray-600">
                  {GlobalText(136)}
                  </h3>
                  <div className="text-2xl md:text-3xl mt-1 flex text-dark font-bold">
                    <p>
                      <span>{debtpercent} %</span>
                    </p>
                  </div>
                </div>
              </aside>
              <section className="mb-5 w-full md:mb-0 md:mr-5 md:w-[552px]">
              <div className="relative mb-6">
                <div className="text-gray-500 text-md">
                  {GlobalText(137)}
                </div>
                <div className="text-gray-800 text-2xl font-bold">
                  {debtsum} { language === 'en' ? 's.' : 'с.'}
                </div>
                  <input
                    type="range"
                    defaultValue={1000}
                    min={1000}
                    max={100000}
                    step={100}
                    onChange={(e) => {
                      setdebtsum(e.target.value)
                    }}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                  />
              </div>
              <div className="relative mb-6">
                <div className="text-gray-500 text-md">
                  {GlobalText(136)}
                </div>
                <div className="text-gray-800 text-2xl font-bold">
                  {debtpercent} %
                </div>
                  <input
                    type="range"
                    defaultValue={20}
                    min={20}
                    max={30}
                    onChange={(e) => {
                      setdebtpercent(e.target.value)
                    }}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                  />
              </div>
              <div className="relative mb-6">
                <div className="text-gray-500 text-md">
                  {GlobalText(138)}
                </div>
                <div className="text-gray-800 text-2xl font-bold">
                  {debtterm} { language === 'en' ? 'months' : language === 'ru' ? 'месяцев' : 'моҳ'}
                </div>
                  <input
                    type="range"
                    defaultValue={12}
                    min={12}
                    max={48}
                    onChange={(e) => {
                      setdebtterm(e.target.value)
                    }}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                  />
              </div>

              </section>

            </div>
          </div>
        </div>
        <div />
      </section>)
    }
    if(component.name === 'deposit_cal'){
      
      return(<section className='pt-10'>
        <div className="border-t border-gray-200 bg-blue-50 py-8 px-6 xl:px-0">
          <div className='mx-auto max-w-6xl py-6'>
          <div className="text-2xl md:text-3xl  md:mb-0 font-bold pb-5">              
              { GlobalText(128) }
              </div>
              <div className="flex w-full flex-wrap items-center justify-between md:container ">              
              <aside className="mt-7 grid w-full gap-5 rounded-3xl bg-white px-4 py-6 md:mt-0 md:w-[384px] md:px-6"
              >
                <div>
                  <h3 className="text-base leading-5 text-gray-600">
                    {GlobalText(140)}
                  </h3>
                  <div className="text-2xl md:text-3xl mt-1 flex text-dark font-bold">
                    <p>
                      <span>{income.toFixed(2)} { language === 'en' ? 's.' : 'с.'}</span>
                    </p>
                  </div>
                </div>
                <div>
                  <h3 className="text-base leading-5 text-gray-600">
                  {GlobalText(142)}
                  </h3>
                  <div className="text-2xl md:text-3xl mt-1 flex text-dark font-bold">
                    <p>
                      <span>{monthlyincome.toFixed(2)} { language === 'en' ? 's.' : 'с.'}</span>
                    </p>
                  </div>
                </div>
                <div>
                  <h3 className="text-base leading-5 text-gray-600">
                  {GlobalText(136)}
                  </h3>
                  <div className="text-2xl md:text-3xl mt-1 flex text-dark font-bold">
                    <p>
                      <span>{deppercent} %</span>
                    </p>
                  </div>
                </div>
                <div>
                <label for="deptax" className="block text-md font-medium leading-5 text-gray-900 inline-flex items-center">
                  <input id="deptax" type="checkbox" checked={deptax} onChange={(e) => setdeptax(e.target.checked)} className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600" /> 
                  <span className='ml-2'>{GlobalText(144)}</span>
                </label>
                </div>
              </aside>
              <section className="mb-5 w-full md:mb-0 md:mr-5 md:w-[552px]">
              <div className="relative mb-6">
                <div className="text-gray-500 text-md">
                  {GlobalText(143)}
                </div>
                <div className="text-gray-800 text-2xl font-bold">
                  {depsum} { language === 'en' ? 's.' : 'с.'}
                </div>
                  <input
                    type="range"
                    defaultValue={10000}
                    min={1000}
                    max={100000}
                    step={100}
                    onChange={(e) => {
                      setdepsum(e.target.value)
                    }}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                  />
              </div>
              <div className="relative mb-6">
                <div className="text-gray-500 text-md">
                  {GlobalText(136)}
                </div>
                <div className="text-gray-800 text-2xl font-bold">
                  {deppercent} %
                </div>
                  <input
                    type="range"
                    defaultValue={13}
                    min={13}
                    max={17}
                    onChange={(e) => {
                      setdeppercent(e.target.value)
                    }}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                  />
              </div>
              <div className="relative mb-6">
                <div className="text-gray-500 text-md">
                  {GlobalText(138)}
                </div>
                <div className="text-gray-800 text-2xl font-bold">
                  {depterm} { language === 'en' ? 'months' : language === 'ru' ? 'месяцев' : 'моҳ'}
                </div>
                  <input
                    type="range"
                    defaultValue={12}
                    min={12}
                    max={48}
                    onChange={(e) => {
                      setdepterm(e.target.value)
                    }}
                    className="w-full h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-700"
                  />
              </div>

              </section>

            </div>
          </div>
        </div>
        <div />
      </section>)
    }
    if(component.name === 'facts'){
      return(<div className='bg-gray-800 py-8 '>
        <div className=' pt-2  my-10 pb-12 mx-auto max-w-6xl px-6 xl:px-0'>
          <div className="container flex w-full justify-between items-center text-titleColor  my-5 mb-6">
            <h2 className="font-bold text-3xl text-white">{component.data.title}</h2>
            <div className="news-navigation flex justify-between gap-7 items-center ">
            <div className="text-3xl  leading-5 flex items-center brt-text-medium text-white">
              {component.data.items &&  component.data.items[slideIndex].year}
                </div>
              <div className="flex items-center gap-3">
                <div className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-[50%] bg-white prev-article text-black p-3"
                  onClick={() => {
                    if (swiperRef.current && swiperRef.current.swiper) {                      
                      swiperRef.current.swiper.slidePrev();
                      const currentIndex = swiperRef.current.swiper.activeIndex;
                      setSlideIndex(currentIndex);
                    }
                  }}>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={8}
                    height={14}
                    viewBox="0 0 8 14"
                    fill="none"
                  >
                    <path
                      d="M7 13.001L1 7.00098L7 1.00098"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
                <div className="flex h-10 w-10 cursor-pointer items-center justify-center rounded-[50%] bg-white next-article text-black p-3" 
                  onClick={() => {
                      if (swiperRef.current && swiperRef.current.swiper) {
                        swiperRef.current.swiper.slideNext();
                        const currentIndex = swiperRef.current.swiper.activeIndex;
                        setSlideIndex(currentIndex);
                      }
                    }}>
                  <svg
                    width={8}
                    height={14}
                    viewBox="0 0 8 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1 13.001L7 7.00098L1 1.00098"
                      stroke="currentColor"
                      strokeWidth={2}
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>
          <div className="overflow-hidden swiper-initialized swiper-horizontal swiper-pointer-events w-full border-t-2 border-gray-500 mb-2" >
              <Swiper
                className="brt-swiper w-full text-white mt-4" 
                ref={swiperRef}
                modules={[Virtual, Navigation, Pagination]}
                slidesPerView={1}
                centeredSlidesBounds={true}
                spaceBetween={30}
                navigation={false}
                onSlideChange={handleSlideChange}
              >
                {component.data.items && component.data.items.map((element, index) => (
                  <SwiperSlide key={element.id} virtualIndex={index} onMouseEnter={() => setIsHovered(index)}
                  onMouseLeave={() => setIsHovered(99)} >
                    <article className='relative w-full'>
                    <div className="top-6 columns-1 md:columns-3 mt-4">
                    {element.items && element.items.map((element2, index2) => (
                      <div className="mb-6 mr-4.2 break-inside-avoid ">
                        <div className="text-lg uppercase leading-4.5 tracking-[1.05px] mb-2.5 text-gray-400">
                        {element2.title}
                        </div>
                        <div className="text-lg leading-6">
                          <div className="rich-text-editor text-gray-200">
                            <p>
                            {element2.text}
                            </p>
                          </div>
                        </div>
                      </div>
                    ))}                     
                  </div>                     
                    </article>
                  </SwiperSlide>
                ))}
              </Swiper>
          </div>
        </div>
      </div>)
    }

  }  

  export default ReturnPage
