import { useEffect, useRef } from 'react';
import { Loader } from '@googlemaps/js-api-loader';
import markerImage from '../imgs/marker.png';

export default function MapComponent({ center, markers }) {
  const mapRef = useRef(null);
  const mapInstance = useRef(null); 
  const GJS_MAP_API  = process.env.REACT_APP_GJS_MAP_API  

  useEffect(() => {
    const loader = new Loader({
      apiKey: GJS_MAP_API, 
      version: 'weekly',
    });

    loader.load().then(() => {
      if (mapRef.current && window.google) {
        mapInstance.current = new window.google.maps.Map(mapRef.current, {
          center: center,
          zoom: 11,
        });


        markers.forEach((markerData) => {
          new window.google.maps.Marker({
            position: markerData.position,
            map: mapInstance.current,
            icon: {
              url: markerImage,
              scaledSize: new window.google.maps.Size(50, 50),
            },
          });
        });
      }
    });
  }, []);

  useEffect(() => {
    if (mapInstance.current) {
      mapInstance.current.setCenter(center);
    }
  }, [center]);

  return <div ref={mapRef} style={{ width: '100%', height: '400px' }} />;
}
